@import 'src/assets/styles/variables';

.list_matches {
  padding: 0 20px;
}

.list_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;

  @media only screen and (max-width: $min-tablet) {
    gap: $gap-small;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $disabled-emphasis;
  }

  .content {
    display: flex;
    gap: 16px;

    img {
      width: 40px;
      height: 48px;
      object-fit: contain;
    }

    .match_infos {
      @media only screen and (min-width: 901px) {
        // For desktop and not small version of the component only
        &:not(.small) {
          display: flex;
          align-items: center;
          gap: 24px;

          .sub_infos {
            display: flex;
            align-items: center;
            gap: 24px;
          }

          .competition_location {
            gap: 24px;

            .competition.small {
              display: none;
            }
          }
        }
      }

      @media only screen and (max-width: 900px) {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      &.small {
        display: flex;
        align-items: center;
        gap: 16px;

        .competition_location {
          gap: 4px;
        }
      }

      .title {
        font-family: $family-bold;
        font-size: 18px;

        @media only screen and (min-width: calc($min-tablet + 1px)) {
          min-width: 120px;
        }
      }

      .date {
        font-family: $family-main;
        color: $primary;
      }

      .competition_location {
        display: flex;


        @media only screen and (max-width: $min-tablet) {
          flex-direction: column;
        }

        .competition {
          font-size: 13px;
          color: $light-emphasis;
        }
      }
    }
  }

  .active_alert {
    width: 120px;
    padding: 8px 24px;
    font-family: $family-condensed;
    font-size: 0.9rem;
    text-transform: uppercase;
    text-align: center;
    color: $primary;
    white-space: nowrap;
  }

  .unit_button {
    width: 120px;

    @media only screen and (max-width: $min-tablet) {
      width: initial;
    }
  }
}
