@import '../../assets/styles/variables';
.wrapper-content {
  position: relative;
  overflow: auto;
  width: 100%;
  padding: 64px $gap-xxlarge;

  >*:not(:last-child) {
    margin-bottom: $gap-medium;
  }
}

.left-content-size {
  max-width: 440px;
  
  >*:not(:last-child) {
    margin-bottom: $gap-xmedium;
  }
}

.center-content-size {
  width: 100%;
  max-width: 440px;
  margin: 0 auto;

  >*:not(:last-child) {
    margin-bottom: $gap-xmedium;
  }
}

.wrapper {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: $light-blue;
}

.not-connected {
  box-sizing: border-box;
  margin-top: 0;
  width: 100%;
  height: 100%;
  background-color: #f6fafd;
}

//
// RESPONSIVE
//
@media only screen and (max-width: $min-tablet) {
  .wrapper {
    flex-direction: column;
  }

  .wrapper-content {
    padding: $gap-medium;
    margin-top: 60px; // Menu's Height
  }

  .not-connected {
    margin-top: 0;
  }
}