@import 'src/assets/styles/variables';

.alerts-list {
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: 24px;
  }

  .see-all {
    svg {
      width: 15px;
      height: auto;
      fill: $primary;
    }
  }

  .form-alert {
    justify-content: flex-end;

    .email-and-phone {
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      @media only screen and (max-width: $min-tablet) {
        flex-direction: column;
      }
    }

    .input-size {
      width: 312px;
    }

    .submit-action {
      margin-left: auto;

      @media only screen and (max-width: $min-tablet) {
        margin: initial;
      }
    }

    button.submit-action {
      width: 100%;
    }

    @media only screen and (max-width: $min-tablet) {
      flex-direction: column;

      .input-size {
        width: 100%;
      }

      .button {
        width: fit-content;
      }
    }
  }

  .asking-preference {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 16px 20px;

    .text {
      flex: 1;

      .title {
        font-family: $family-bold;
      }

      .content {
        font-size: 14px;
      }
    }
  }
}