@import '/src/assets/styles/variables';

.card-component {
  position: relative;

  .member-card {
    width: 100%;
    min-width: 200px;

    &.not-member {
      opacity: 0.3;
    }
  }

  .member-card-info {
    position: absolute;
    top: 22%;
    left: 9%;
    text-align: left;
    font-size: 0.75em;
    color: #FFFFFF;

    &.dark {
      color: $default-emphasis;
    }
    
    .name {
      font-family: $family-bold;
      margin-bottom: $gap-xsmall;

      .lastname {
        text-transform: uppercase;
      }
    }
  }
}

.card-format {
  position: relative;
  width: 100%;
  padding-top: calc(100% * 0.605);
  color: white;
}

.om-card {
  position: absolute;
  top: 0; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .content {
    position: relative;
    width: 100%;
    height: 100%;

    &.display-back {
      .front {
        transform: rotateY(-180deg);
      }
      .back {
        transform: rotate(0);
      }
    }

    .front, .back {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      transition: all .6s ease;
      border-radius: 12px;
      background-color: #0f2439;
      padding: 16%;
      text-align: center;
    }

    .front {
      img {
        height: 100%;
        width: 100%;
      }
    }
    
    .back {
      transform: rotateY(180deg);
    }
  }
}

//
// RESPONSIVE
//
@media only screen and (max-width: $min-tablet) {
  .member-card {
    min-width: auto;
  }
}