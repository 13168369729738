@import '../../assets/styles/variables';

.api-error {
  margin: auto 0;
  font-size: 0.85rem;
}

.dashboard-header {
  margin: -64px -80px 24px;

  .background-polygon {
    width: 100%;
    background-color: #E3F2FA;
    padding: 64px 80px 24px;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;

    > *:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .personalized-shirt {
    position: relative;
    
    img.img-personalized-shirt {
      position: relative;
      z-index: 1;
      width: 200px;
    }

    .circle-aura {
      position: absolute;
      bottom: 10px;
      width: 100%;
      height: 24px;
      background-color: #D3EAF5;
      border-radius: 50%;
    }
  }

  .informations {
    >*:not(:last-child) {
      margin-bottom: 24px;
    }

    .no-picture svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translate(0, 8px);
    }

    .profile-picture {
      width: 80px;
      height: 80px;
      // TODO Delete
      background-color: #848484;
      border-radius: 50%;
      // END TODO
  
      img {
        border-radius: 50%;
      }
    }
  
    .badge {
      position: relative;
      transform: translate(0, -4px);
      display: block;
      box-sizing: border-box;
      width: 80px;
      background-color: #092C4D;
      border-radius: 4px;
      padding: 4px 6px;
    }
  
    .account-infos {
      display: flex;
      gap: 8px;
      font-size: 0.9rem;
    }
  }

  svg.background {
    height: 32px;
    width: 100%;
    transform: scale(1, -1);
  }
}

.dashboard-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 40px;
}

.next-game-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;

  .light-background {
    background-color: $light-blue;
    border-radius: 6px;
    padding: 8px 14px;
    line-height: 1;
    text-align: center;

    .day {
      font-family: $family-bold;
    }

    .month {
      color: $light-emphasis;
    }
  }

  .match {
    .home-away {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      margin-bottom: 4px;

      .home, .away {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
      }

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
    }
  }
}

.background-player-picture {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  background-color: $light-blue;
  border-radius: 8px;
  padding: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

}

.player-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;

  .player-name {
    font-family: $family-title;
    text-transform: uppercase;
    line-height: 1.1;
  }

  .player-position {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    margin-bottom: 16px;
  }

  .bloc-player-stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .player-stats {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    text-align: center;

    .stat {
      display: flex;
      flex-direction: column;
      gap: 4px;
      background-color: $light-blue;
      border-radius: 6px;
      padding: 8px;
      font-size: 0.7rem;
      line-height: 0.7rem;
    }

    .number {
      font-family: $family-title;
    }
  }

  .no-player-infos {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .list-download-buttons {
    display: flex;
    gap: 16px;

    a {
      display: flex;
      align-items: center;
      gap: 8px;
      background: #000000;
      border-radius: 6px;
      padding: 4px 10px;
      font-size: 0.8rem;
      line-height: 1rem;
      color: $white;

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }

      .store {
        font-family: $family-bold;
        font-size: 0.9rem;
      }

      &:hover {
        cursor: pointer;
        color: #FFFFFFCC;
      }
    }
  }
}

.info-block {
  background: radial-gradient(circle at left top, rgb(14, 173, 222) 0%, rgb(14, 150, 210) 50%, rgb(14, 150, 210) 80%, rgb(10, 123, 173) 100%);
  color: $white;
  border-radius: 8px;
  padding: 12px 24px;

  svg {
    fill: $white;
  }

  .header {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: $family-bold;

    div::first-letter {
      text-transform: uppercase;
    }
  }

  .content {
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 24px;

    span::first-letter {
      text-transform: uppercase;
    }

    a {
      white-space: nowrap;
      color: $white;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.block {
  .title {
    margin-bottom: 6px;
    font-family: $family-bold;
    font-size: 0.9rem;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.pbb-block {
  position: relative;
  border-radius: 8px;
  padding: 18px 24px;

  &.legend {
    background: radial-gradient(circle at left bottom, #FFF2E9 0%, #DFB8A6 90%);
  }

  &.member {
    background: radial-gradient(circle at left bottom, #000518 0%, #182C41 20%, #0472AD 85%, #85DAF7 100%);
    color: $white;
  }

  .pbb-motif {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.24;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/img/cbb/motif_vague2.jpg");
    background-position: initial;
    background-size: cover;
  }

  .content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .content-left {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .header {
      font-family: $family-pbb;
      text-transform: uppercase;
      font-size: 0.8rem;
    }

    .pbb-id {
      font-size: 0.8rem;
      line-height: normal;
    }

    img.logo {
      width: 120px;
    }

    .see-pbb-button {
      width: fit-content;
      border: $white;
      border-radius: 4px;
      padding: 4px 16px;
      font-size: 0.9rem;

      &:not(:hover) {
        background-color: $white;
        color: $pbb-button;
      }

      &:hover {
        background-color: $pbb-button;
      }
    }
  }
}

.shortcuts {
  display: flex;
  gap: 8px;
  & > * {
    width: 100%;
  }
}

.block .partner {
  width: 100%;
  min-width: initial;
}

//
// RESPONSIVE
//
@media only screen and (max-width: 1200px) {
  .dashboard-header {
    .name .page-title {
      flex-direction: column;
      font-size: 1.5rem;
      line-height: 1.2rem;
    }

    .name {
      justify-content: center;
    }
  }

  .dashboard-grid {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}

@media only screen and (max-width: $min-tablet) {
  .dashboard-header {
    margin: -24px;

    .background-polygon > *:not(:last-child) {
      margin: 0 auto 24px;
    }

    .background-polygon {
      flex-direction: column;
      gap: 0;
      padding: 40px 40px 24px;
    }

    .profile-picture, .badge {
      margin: auto;
    }
    
    .account-infos {
      justify-content: center;
    }
  }

  .player-name {
    text-align: center;
  }

  .player-block .list-download-buttons {
    flex-direction: column;
    gap: 8px;
    width: fit-content;
  }
}

@media only screen and (min-width: $min-tablet) and (max-width: 860px) {
  .background-player-picture {
    display: none;
  }
}