.sCheckbox{
	height: 0;
	width: 0;
	visibility: hidden;
}

.sLabel {
	/* transform: scale(0.20); */
	cursor: pointer;
	text-indent: -9999px;
	width: 46px;
	height: 27px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

.sLabel:after {
	content: '';
	position: absolute;
	top: 4px;
	left: 5px;
	width: 19px;
	height: 19px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

.sCheckbox:checked + label {
	background: #0E96D2;
}

.sCheckbox:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

.sCheckbox:disabled + .sLabel {
	opacity: 0.4;
}