@import 'src/assets/styles/variables';

.delete-account {
  .subtitle {
    margin-bottom: 6px;
  }

  >*:not(:last-child) {
    margin-bottom: 24px;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;

    button {
      width: fit-content;
    }

    @media only screen and (max-width: $min-tablet) {
      flex-direction: row-reverse;
      margin-left: auto;
    }
  }

  .last-actions {
    display: flex;
    gap: 12px;

    button {
      width: fit-content;
    }

    @media only screen and (max-width: $min-tablet) {
      flex-direction: column;

      button {
        width: 100%;
      }
    }
  }

  .restriction-line {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    margin-bottom: 2px;

    .subheader {
      font-size: 12px;
      
      @media only screen and (max-width: $min-tablet) {
        display: block;
      }
    }
  }

  .step {
    .subheader {
      font-size: 15px;
      color: $medium-emphasis;
      margin-bottom: 8px;
    }

    li:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .form-group {
    label {
      font-size: 14px;
    }

    @media only screen and (min-width: calc($min-tablet + 1px)) {
      input {
        max-width: 296px;
      }
    }
  }
}

.confirm-popup {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;

  .actions >*:not(:last-child) {
    margin-bottom: 8px;
  }
}