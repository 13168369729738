@import '/src/assets/styles/variables';

.menu {
	overflow: auto;
	display: flex;
	min-width: 270px;
	max-width: 270px;
	flex-direction: column;
	gap: $gap-large;
	background-color: $white;
	padding: $gap-xmedium $gap-medium;
	border-right: 1px solid $disabled-emphasis;

	.user-profile {
		font-family: $family-main;
	}
	
	ul {
		all: unset;
		list-style-type: none;
		z-index: 2;
		padding: 0;
		margin: 0;
	
		.mobile-padding > *:not(:last-child) {
			margin-bottom: $gap-large;
		}
	
		.label {
			font-family: 'Roboto Condensed Bold';
			color: $light-emphasis;
			margin-bottom: $gap-xsmall;
	
			&::first-letter {
				text-transform: uppercase;
			}
		}
	
		a {
			all: unset;
			cursor: pointer;
			display: block;
			padding: 0.5rem 1rem;
			border-radius: $radius;
			font-family: $family-condensed;
	
			&::first-letter {
				text-transform: uppercase;
			}
	
			&:hover {
				background-color: $grey-20;
				color: inherit;
			}
	
			&.active {
				background-color: $light-primary;
			}
	
			.menu-item {
				display: flex;
				align-items: center;
				gap: $gap-xsmall;
	
				.name::first-letter {
					text-transform: uppercase;
				}
			}

			&.red {
				color: $red;
			}

			&.go-back {
				padding: 0;
				font-size: 0.9em;
				font-family: 'Roboto Condensed Bold';

				&:hover {
					background-color: transparent;
					color: $complementary;

					svg {
						fill: $complementary;
					}
				}
			}
		}
	}

	.top-bar > a {
		all: unset;
		cursor: pointer;
		display: block;
		padding: 0.5rem 1rem;
		border-radius: $radius;
		font-family: $family-condensed;
	}

	.language {
		display: flex;
		gap: 4px;
		font-size: 0.7rem;
		font-family: $family-bold;

		button {
			opacity: 0.4;
		}

		button.active, button:hover {
			opacity: 1;
		}

		button:hover {
			color: $complementary;
		}
	}
}

//
// RESPONSIVE
//
@media only screen and (max-width: $min-tablet) {
	.menu {
		position: fixed;
		z-index: 400;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		border: none;
		padding: 0;

		.top-bar {
			z-index: 10;
			display: flex;
			width: 100%;
			height: 60px;
			justify-content: space-between;
			align-items: center;
			padding: 0 $gap-xmedium;

			> a {
				padding: 0;
			}
		}
	
		.mobile-padding {
			padding: $gap-medium;
			margin-bottom: 100px;
		}
	
		ul {
			position: fixed;
			overflow: hidden;
			background: $white;
			transform: translate(calc(100% + $gap-medium * 2));
			transition: translate .2s ease-out;
			margin-top: 60px;
			width: 100%;
			height: 100%;
			background-color: #FFFFFF;
		}

		ul.visible {
			transform: translate(0);
			overflow: auto;
		}

		.mobile svg {
			transition: all .2s ease-in-out;

			&:active {
				transform: scale(1.1);
				transition: .1s;
			}
		}

		.language {
			font-size: 0.9rem;
		}
	}
}