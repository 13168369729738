@import 'src/assets/styles/variables';

.alert-page {
  > *:not(:last-child) {
    margin-bottom: 24px;
  }

  .alert-panel {
    .teams-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;

      img {
        max-height: 80px;
        width: auto;
        max-width: 60px;
      }
    }

    .form-alert {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 8px;
    }

    img {
      width: 100%;
    }

    > *:not(:last-child) {
      margin-bottom: 24px;
    }

    .datetime {
      font-family: $family-main;
      font-size: 18px;
      color: $primary;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    .match-infos {
      display: flex;
      gap: 4px;
      font-size: 14px;

      .location {
        color: $light-emphasis;
      }
    }

    hr {
      margin: 32px auto;
    }
  }
}

.validation-alert {
  .title {
    font-family: $family-bold;
    font-size: 1.4rem;
  }

  .footer {
    @media only screen and (min-width: calc($min-tablet + 1px)) {
      margin-left: -32px;
      width: calc(100% + 64px);
    }

    .first-border {
      height: 2px;
      background-color: $prime;
    }
  
    .second-border {
      height: 2px;
      background-color: $primary;
    }
  
    .author {
      padding: 16px 0;
      font-size: 0.7rem;
    }
  }
}

@media only screen and (max-width: $min-tablet) {
  .alert {
    &.card {
      all: unset;
    }
  }

  .validation-alert .footer {
    margin-left: -24px;
    width: calc(100% + 48px);
  }
}