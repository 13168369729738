@import '/src/assets/styles/variables';

.shortcut {
  cursor: pointer;
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  padding: 0;
  text-align: center;
  font-family: 'Roboto Condensed Bold';

  &:hover {
    background-color: #e0eff9;
  }

  div::first-letter {
    text-transform: uppercase;
  }
}