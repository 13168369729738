@import '/src/assets/styles/variables';

.page-title {
  display: flex;
  gap: 12px;
  font-family: $family-title;
  font-size: 2rem;
  text-transform: uppercase;
  margin: 0;
  line-height: 1;

  &::first-letter {
    text-transform: uppercase;
  }

  &.primary,
  .primary {
    color: $primary;
  }

  @media only screen and (max-width: $min-tablet) {
    flex-direction: column;
    gap: 4px;
    text-align: center;
    font-size: 1.4rem;
  }
}