#confirm-box {
    .content {
        z-index: 1000;
        position: fixed;
        top: 0;
        width: 100%;
        height: 100vh;
        background-color: $light-emphasis;
        display: grid;
        justify-content: center;
        align-content: center;
      }

      .modal {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 20px;
        width: 350px;
        margin: 0 auto;
        position: relative;
      }

      .modal-description p {
        text-align: justify;
      }

      .modal-description, .model-footer {
        padding: 20px;
      }

      .modal-footer {
        display: flex;
        flex-direction: row;
        justify-content: right;
        gap: 8px;
      }
}
