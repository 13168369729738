@import '/src/assets/styles/variables';

.login_close {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  padding-top: 24px;

  @media only screen and (max-width: $min-tablet) {
      position: sticky;
      top: 0;
      width: 100%;
      background-color: #f7fafd;
  }

  svg {
    cursor: pointer;
    margin-left: auto;
  
    @media only screen and (max-width: $min-tablet) {
        position: initial;
    }
  }
}

.business {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 48px;
  height: 100%;
  min-height: 455px;

  .account-valid-icon {
    margin-bottom: 24px;
  }

  >*:not(:last-child) {
    margin-bottom: $gap-medium;
  }

  .card {
    position: relative;
    width: 50%; /*70%;*/
    padding-top: 32%; /*44%;*/
    margin: auto;
    border-radius: 12px;
    background-color: #0f2439;
    color: white;
  }

  .account-name {
    font-family: $family-bold;
    font-size: 20px;
    text-transform: lowercase;
    margin-bottom: 24px;

    &::first-letter {
      text-transform: lowercase;
    }
  }

  .card-content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: $gap-small;

    .member-header {
      margin-bottom: $gap-small;
    }
  }

  .valid {
    color: $green;
  }

  svg.valid {
    fill: $green;
  }

  .invalid {
    color: $red;
  }

  svg.invalid {
    fill: $red;
  }

  svg.city-line {
    position: absolute;
    bottom: 16px;

    path {
      stroke: #FFFFFF66;
    }
  }
}

.scan-container {
  display: grid;
  justify-content: center;
  align-content: center;
}

.video-wrapper {
  display: flex;
  position: relative;
}

.qr-code {
  width: 100%;
}

.video-frame {
  visibility: hidden;
  position: absolute;
  height: calc(100% - 36px);
  width: calc(100% - 36px);
  margin: 18px;
  background: linear-gradient(to right,white 2px,transparent 2px) 0 0,linear-gradient(to right,white 2px,transparent 2px) 0 100%,linear-gradient(to left,white 2px,transparent 2px) 100% 0,linear-gradient(to left,white 2px,transparent 2px) 100% 100%,linear-gradient(to bottom,white 2px,transparent 2px) 0 0,linear-gradient(to bottom,white 2px,transparent 2px) 100% 0,linear-gradient(to top,white 2px,transparent 2px) 0 100%,linear-gradient(to top,white 2px,transparent 2px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}

.video-frame-background {
  visibility: hidden;
  position: absolute;
  height: calc(100% - 36px);
  width: calc(100% - 36px);
  margin: 18px;
  outline: 18px solid #0f243933;
}

.video-text {
  position: absolute;
  width: 100%;
  margin-top: 24px;
  font-family: $family-bold;
  text-align: center;
  color: #FFFFFF;
}

//
// RESPONSIVE
//
@media only screen and (max-width: $min-tablet) {
  .business {
    .card {
      width: 100%;
      padding-top: 62.85%;
    }
  }
}