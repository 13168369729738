.box {
  position: absolute;
  top: 0;
  transform: rotate(80deg);
  left: 20%;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  from {
    transform: rotate(360deg);
  }
}

#shape10,
#shape9,
#shape8,
#shape1 {
  transform-box: fill-box;
  transform-origin: center;
  -webkit-animation: rotate-center 2s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate both;
  animation: rotate-center 2s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate both;
}

#cir1,
#cir2,
#cir3 {
  transform-box: fill-box;
  transform-origin: center;
  -webkit-animation: rotate-scale-down-diag-2 2s linear infinite both;
  animation: rotate-scale-down-diag-2 2s linear infinite both;
}

#cir4,
#cir5,
#cir6 {
  transform-box: fill-box;
  transform-origin: center;
  -webkit-animation: rotate-scale-down-diag-2 1s linear infinite both;
  animation: rotate-scale-down-diag-2 1s linear infinite both;
}

/**
   * ----------------------------------------
   * animation rotate-center
   * ----------------------------------------
   */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/**
   * ----------------------------------------
   * animation rotate-scale-down-diag-2
   * ----------------------------------------
   */
@-webkit-keyframes rotate-scale-down-diag-2 {
  0% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 0deg);
    transform: scale(1) rotate3d(-1, 1, 0, 0deg);
  }

  50% {
    -webkit-transform: scale(0.5) rotate3d(-1, 1, 0, 180deg);
    transform: scale(0.5) rotate3d(-1, 1, 0, 180deg);
  }

  100% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 360deg);
    transform: scale(1) rotate3d(-1, 1, 0, 360deg);
  }
}

@keyframes rotate-scale-down-diag-2 {
  0% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 0deg);
    transform: scale(1) rotate3d(-1, 1, 0, 0deg);
  }

  50% {
    -webkit-transform: scale(0.5) rotate3d(-1, 1, 0, 180deg);
    transform: scale(0.5) rotate3d(-1, 1, 0, 180deg);
  }

  100% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 360deg);
    transform: scale(1) rotate3d(-1, 1, 0, 360deg);
  }
}

.omCheckbox {
  display: none;
}

.omCheckboxLabel {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-color: #0f243966;
  transform: translateY(-50%);
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s ease transform, 0.2s ease background-color,
    0.2s ease box-shadow;
  overflow: hidden;
  z-index: 1;
}

.omCheckboxLabel:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  width: 90px;
  height: 90px;
  margin: 0 auto;
  background-color: #FFFFFF;
  transform: translateY(-50%);
  border-radius: 50%;
  transition: 0.2s ease width, 0.2s ease height;
}

.omCheckboxLabel:active {
  transform: translateY(-50%) scale(0.9);
}

.tick_mark {
  position: absolute;
  top: -1px;
  right: 0;
  left: 0;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  margin-left: 14px;
  transform: rotateZ(-40deg);
}

.tick_mark:before,
.tick_mark:after {
  content: "";
  position: absolute;
  background-color: #fff;
  border-radius: 2px;
  opacity: 0;
  transition: 0.2s ease transform, 0.2s ease opacity;
}

.tick_mark:before {
  left: 0;
  bottom: 0;
  width: 10px;
  height: 30px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.23);
  transform: translateY(-68px);
}

.tick_mark:after {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.23);
  transform: translateX(78px);
}

.omCheckbox:checked+.omCheckboxLabel {
  background-color: #0E96D2;
}

.omCheckbox:checked+.omCheckboxLabel:before {
  width: 0;
  height: 0;
}

.omCheckbox:checked+.omCheckboxLabel .tick_mark:before,
.omCheckbox:checked+.omCheckboxLabel .tick_mark:after {
  transform: translate(0);
  opacity: 1;
}