@import '/src/assets/styles/variables';

.login-content {
  height: 100%;
  display: flex;

  .scroll-content {
    overflow: auto;
    width: 460px;
    max-width: 500px;
    display: flex;
    margin: auto;
    padding: 2em;
  }

  @media only screen and (min-width: 768px) {
    .scroll-content::-webkit-scrollbar {
      display: none;
    }
  }

  .form {
  //   margin: auto;
  //   display: flex;
  //   flex-direction: column;
  //   gap: $gap-medium;
  //   text-align: center;

  //   .social-provider-login {
  //     display: flex;
  //     flex-direction: column;
  //     gap: 16px;

  //     button {
  //       width: 100%;
  //     }
  //   }

    .separator {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid $placeholder-emphasis;
      line-height: 0.1em;
      margin: $gap-small 0;

      span {
        background-color: $white;
        padding: 0 $gap-small;
        font-size: 0.875rem;
        color: $medium-emphasis;
      }
    }

    .login-description {
      font-family: $family-main;
      font-size: 1.2rem;
    }

    .logo-om {
      height: 80px;
      margin: auto;
    }

    form {
      label {
        display: none;
        font-size: 0.813rem;
        color: $medium-emphasis;
      }
    }
  }

  .image-content {
    position: relative;
    width: 50%;
    height: 100%;
    padding: $gap-medium;
    padding-left: 0;

    >div {
      height: 100%;
      position: relative;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: $radius;
    }

    .communication {
      position: absolute;
      top: $gap-xxlarge;
      width: 100%;
      text-align: center;

      div {
        width: 72%;
        margin: auto;
        border-radius: $radius;
        background-color: rgb(255 255 255 / 30%);
        backdrop-filter: blur(10px);
        padding: $gap-xmedium;
        color: $white;
      }
    }
  }

  .partners {
    display: flex;
    justify-content: center;
    gap: $gap-medium;
    margin-top: $gap-medium;

    .omc-logo {
      height: 30px;
    }
  }


}

//
// RESPONSIVE
//
@media only screen and (max-width: $min-tablet) {
  .login-content {
    .image-content {
      display: none;
    }

    .scroll-content {
      width: 100%;
    }
  }
}

.email-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.magic-group {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: end;

  svg {
    width: 15px;
  }
}

.magic-group:hover {
  a {
    color: $red !important;
  }

  svg {
    fill: $red;
  }
}

#magic-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  label {
    text-align: justify;
    cursor: pointer;
  }

  svg {
    width: 30px;
  }
}

#magic-link:hover {
  color: $red;

  svg path {
    fill: $red;
  }
}