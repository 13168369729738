//
// GLOBAL
//
.display-none {
  display: none !important;
}

.mobile {
  display: none;
}

.bold {
  font-family: $family-bold;
}

.medium {
  font-family: $family-main;
}

.disabled-emphasis {
  color: $disabled-emphasis;
}

.light-emphasis {
  color: $light-emphasis;
}

.text-center {
  text-align: center;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.first-letter-uppercase::first-letter {
  text-transform: uppercase;
}

.padding-xlarge {
  padding: $gap-xlarge;
}

.wrapper-full-page {
  display: grid;
  place-items: center;
  align-content: center;
  height: 100%;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.card {
  border: none;
  box-shadow: 0 2px 2px #d8e3ea; // 0 0.6rem 0.6rem 0 #d8e3ea;
  border-radius: $radius;
  padding: $gap-medium $gap-xmedium;

  .header {
    position: relative;
    font-family: $family-bold;
    padding-bottom: 8px;
  }

  &.accordion:not(.display) .header {
    padding-bottom: 0;
  }

  &.accordion .header {
    pointer-events: none;
    
    &::after {
      position: absolute;
      right: 0;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='28' viewBox='0 -960 960 960' width='28'%3E%3Cpath d='M479.8-348q-7.2 0-13.5-2.5T455-358L265.338-547.662Q254-559 254.5-573.5 255-588 266-599t25.5-11q14.5 0 25.5 11l163 164 164-164q11-11 25.5-10.5T695-598q11 11 11 25.5t-11.338 25.782L505-358q-5.4 5-11.7 7.5-6.3 2.5-13.5 2.5Z'/%3E%3C/svg%3E");
    }
  }
}

a {
  text-decoration: none;

  &.button.tertiary {
    display: block;
    width: 100%;
    cursor: pointer;
    pointer-events: auto;
    border-radius: $radius;
    background-color: $primary;
    padding: 0.5em 1.5em;
    color: $white;
    text-decoration: none;
    font-family: 'Roboto';
    font-size: 16px;
    line-height: normal;

    &::first-letter {
      text-transform: uppercase;
    }

    &:disabled {
      opacity: 32%;
      filter: saturate(50%);
      cursor: auto;
    }

    width: fit-content;
    pointer-events: auto;
    display: flex;
    align-items: center;
    gap: 4px;
    border: none;
    background-color: transparent;
    padding: 0;
    font-family: $family-condensed;
    font-size: 15px;
    text-transform: uppercase;
    color: $primary;

    svg {
      fill: $primary;
    }

    &:hover {
      color: $complementary;

      svg {
        fill: $complementary;
      }
    }
  }
}

a.go-back {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 16px;
  color: initial;
  text-decoration: none;

  &:hover {
    color: $complementary;
    svg { fill: $complementary; }
  }
}

// TODO: Delete - Used Signup.tsx
.flex {
  display: flex;
}

.marginLeft30 {
  margin-left: 30px;
}

.x-small {
  font-size: 0.7rem;
}
// ENDTODO

//
// COMPONENTS
//
html {
  font-size: 16px;

  body {
    width: 100%;
    height: 100vh;
    color: $default-emphasis;
    font-family: 'Roboto', sans-serif;
    margin: 0;

    iframe {
      position: absolute;
    }
  }
}

#root {
  overflow: hidden;
  height: 100%;
  padding-top: 40px; // Desktop Topbar size
  display: flex;
  flex-direction: column;
}

.paddingTop0 {
  padding-top: 0px !important;
}

hr {
  margin-top: 0;
}

form {
  .form-group label {
    font-size: .8em;
    margin-left: 2px;
    text-overflow: ellipsis;
    @media only screen and (min-width: calc($min-tablet + 1px)) {
      white-space: nowrap;
    }
  }

  .required::after {
    color: #eb4f2a;
    content: " *";
  }
}

button {
  border: none;
  background: none;
  
  &:hover {
    svg {
      fill: $complementary;
    }
  }
}

p {
  margin: 0;
}

input[type=email],
input[type=password],
input[type=text],
input[type=date],
input[type=tel],
input[type=button],
input[type=button],
input[type=submit],
select {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $placeholder-emphasis;
  background-color: $white;
  padding: 0.75em 1.25em;
  border-radius: $radius;
  line-height: 1em;
  color: $default-emphasis;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  appearance: none;
}

input[type=tel] {
  padding: 0.75em 1.25em 0.75em 3.8em;
}


input[type=radio] {
  &.btn-check:checked + .btn {
    background-color: $primary;
    border-color: $primary;
  }

  &.btn-check:not(:checked) + .btn:hover {
    border-color: $primary;
    background-color: $primary-hover;
    color: $default-emphasis;
  }
}

svg.button {
  cursor: pointer;
}

.tag {
  width: fit-content;
  border-radius: 6px;
  background-color: #E7E9ED;;
  padding: 0.05em 0.6em;
  font-size: 0.75rem;
  margin-bottom: 4px;
}

//
// ---------------------------------------------------------------------------
// ---------------------------------------------------------------------------
// OLD
// ---------------------------------------------------------------------------
// ---------------------------------------------------------------------------
.btn-danger {
  background-color: #CD5C5C !important;
  border: 2px solid #CD5C5C !important;
}

.btn-danger:hover,
.btn-danger:focus {
  color: #CD5C5C !important;
  background-color: #FFFFFF !important;
}

.alert-secondary {
  background-color: #fbfbfb;
}

#signup-form>label {
  text-align: justify;
}

#delete-form>label {
  text-align: justify;
}

#lang {
  display: flex;
  margin-top: 50px;
  flex-direction: row;
  justify-content: space-around;
}

footer {
  position: fixed;
  bottom: 0px;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

menu {
  display: flex;
  align-items: center;
  padding-left: 0px;
}

#ok_circle {
  color: green;
  position: absolute;
  // top: 0.5em;
  right: 20px;
}

#ko_circle {
  color: #CD5C5C;
  position: absolute;
  top: 0.5em;
  right: 20px;
}

.button-icon {
  position: absolute;
  top: 0;
  right: 20px;
  width: 24px;
  cursor: pointer;
}

.transform02 {
  transform: scale(0.2);
}

.alert {
  text-align: center;
  margin-bottom: 0px;
}

.col-8 {
  display: grid;
}

.password-rules {
  padding-left: 8px;
}

.rule-valide {
  color: #59C560;
}


//
// RESPONSIVE
//
/* Extra small devices (phones, $min-tablet and down) */
@media only screen and (max-width: $min-tablet) {}
/* Small devices (portrait tablets and large phones, $min-tablet and up) */
@media only screen and (min-width: $min-tablet) {}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}
@media only screen and (orientation: landscape) {}

//
//
// This is the only screen size adaptation implemented for now
@media only screen and (max-width: $min-tablet) {
  html body {
    height: 100%;
    background: #F6FAFD;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  }

  #root {
    overflow: auto;
  }

  //
  // GLOBAL
  //
  .wrapper-full-page{
    padding: $gap-xmedium;
  }

  .desktop {
    display: none !important;
  }

  .mobile {
    display: block;
  }

  .padding-xlarge {
    padding: $gap-xmedium;
  }

  .card {
    padding: $gap-small $gap-medium;
  }
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

//
//
// Accessibility
#root.general-accessibility {
  button:not(.accessibility),
  a.button {
    font-size: 1.2rem;
  }

  .btn-group label {
    font-size: 1.2rem;
    padding-left: 0;
    padding-right: 0;
  }
}

