@import '../../assets/styles/variables';

.scroll {
  position: relative;
}

.slide {
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: $gap-small;
  padding-bottom: 2px;

  @media only screen and (min-width: calc($min-tablet + 1px)) {
    .blur {
      position: absolute;
      right: 0;
      width: 40px;
      height: 100%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .7) 40%, #ffffff 70%);
    }
  }
}

@media only screen and (max-width: $min-tablet) {
  .slide {
    width: calc(100% + 24* 2px);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-right: 0;
    margin-left: -24px;
    padding: 0 24px 2px;

    > * {
      min-width: 80%;
    }
  }
}