@import '../../assets/styles/variables';

.partner {
  display: inline-block;
  pointer-events: none;
  width: $min-tablet;
  min-width: $min-block;

  .card {
    pointer-events: none;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: $gap-xsmall;

    &.border-pbb {
      border: 2px solid $pbb;
    }

    img {
      height: 40px;
      width: auto;
      max-width: 65%;
      object-fit: contain;
    }

    button {
      pointer-events: auto;
    }

    .code-promo {
      display: flex;
      align-items: center;
      gap: $gap-xsmall;
      font-family: $family-bold;
    }
  }
}

//
// RESPONSIVE
//
@media only screen and (max-width: $min-tablet) {
  .partner {
    display: inline-block;
    width: 280px;
    min-width: 280px;

    .card {
      pointer-events: auto;
    }

    img {
      max-width: 80%;
    }
  }
}