@import '../../assets/styles/variables';

.preference-form {
  .row {
    gap: 8px;

    label {
      text-align: left;
    }

    .category {
      font-family: $family-main;
      font-size: 1.5rem;
      margin: 0;
    
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}