@import '../../assets/styles/variables';

.security {
  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  .card-content {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    @media only screen and (max-width: $min-tablet) {
      flex-direction: column;
      gap: 16px;

      a {
        margin-left: auto;
      }
    }

    .content {
      width: 100%;
    }
    
  }
}