@import '/src/assets/styles/variables';

#loading-spinner {
  .content {
    z-index: 1000;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: $light-emphasis;
    display: grid;
    justify-content: center;
    align-content: center;

    .spinner {
      width: 56px;
      height: 56px;
      border: 6px dotted $primary;
      border-radius: 50%;
      animation: spinner 2s linear infinite;
    }
  }
}

// Animation
@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*
## Device = Portrait DataTablets, Smartphones & iPhone
*/
@media (max-width:768px) {
  #toast-container-main {
  }
}